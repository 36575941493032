import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    data: null,
    smf: [],
    dokter: [],
    medis: [],
    tindakan: []
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload
    },
    SET_SMF(state, payload) {
      state.smf = payload
    },
    SET_DOKTER(state, payload) {
      state.dokter = payload
    },
    SET_MEDIS(state, payload) {
      state.medis = payload
    },
    SET_TINDAKAN(state, payload) {
      state.tindakan = payload
    }
  },

  actions: {
    async save({ state }, form) {
      try {
        const url = form.new
          ? '/ruangan/store'
          : '/ruangan/update/' + form.id + '?_method=PUT'
        const { data } = await axios.post(url, form)

        return data.success ? data.data : false
      } catch (err) {
        return false
      }
    },

    async delete({ state }, id) {
      try {
        const { data } = await axios.delete(`/ruangan/destroy/${id}`)

        return data.success
      } catch (err) {
        return false
      }
    },

    data(state, payload) {
      state.commit('SET_DATA', payload)
    },

    smf(state, payload) {
      state.commit('SET_SMF', payload)
    },

    medis(state, payload) {
      state.commit('SET_MEDIS', payload)
    },

    dokter(state, payload) {
      state.commit('SET_DOKTER', payload)
    },

    tindakan(state, payload) {
      state.commit('SET_TINDAKAN', payload)
    },

    async getSmf(state, id) {
      try {
        let { data } = await axios.get(`ruangan/smf/${id}`)

        if (data.success === true && data.data.length > 0) {
          state.commit('SET_SMF', data.data)

          return data.data
        } else {
          state.commit('SET_SMF', [])
          return null
        }
      } catch (err) {
        console.error(err)
      }
    },
    async getDokter(state, id) {
      try {
        let { data } = await axios.get(`ruangan/dokter/${id}`)

        if (data.success === true && data.data.length > 0) {
          state.commit('SET_DOKTER', data.data)

          return data.data
        } else {
          state.commit('SET_DOKTER', [])
          return null
        }
      } catch (err) {
        console.error(err)
      }
    },
    async getMedis(state, id) {
      try {
        let { data } = await axios.get(`ruangan/medis/${id}`)

        if (data.success === true && data.data.length > 0) {
          state.commit('SET_MEDIS', data.data)

          return data.data
        } else {
          state.commit('SET_MEDIS', [])
          return null
        }
      } catch (err) {
        console.error(err)
      }
    },
    async getTindakan(state, id) {
      try {
        let { data } = await axios.get(`ruangan/tindakan/${id}`)

        if (data.success === true && data.data.length > 0) {
          state.commit('SET_TINDAKAN', data.data)

          return data.data
        } else {
          state.commit('SET_TINDAKAN', [])
          return null
        }
      } catch (err) {
        console.error(err)
      }
    },
    async get(state) {
      try {
        const { data } = await axios.get(`ruangan`)

        if (data.data.length > 0) {
          state.commit('SET_DATA', data.data)

          return data.data
        }
        state.commit('SET_DATA', [])
        return null
      } catch (err) {
        console.error(err)
        return null
      }
    }
  },

  getters: {
    data(state) {
      return state.data
    },
    smf(state) {
      return state.smf
    },
    dokter(state) {
      return state.dokter.filter((d) => d.master_dokter).map((dokter) => ({
        ...dokter,
        id: dokter.master_dokter.master_pegawai.id,
        name: `${dokter.master_dokter.master_pegawai.gelar_depan}. ${dokter.master_dokter.master_pegawai.nama}, ${dokter.master_dokter.master_pegawai.gelar_belakang}`.replaceAll(
          'null',
          ''
        )
      }))
    },
    medis(state) {
      return state.medis.filter((d) => d.master_pegawai).map((staff) => ({
        ...staff,
        id: staff.master_pegawai.id,
        name: staff.master_pegawai.nama
      }))
    },
    tindakan(state) {
      return state.tindakan
    }
  }
}
