<template>
  <div :class="extraClass">
    <flat-pickr
      v-model="dateTime"
      size="xs"
      :disabled="disabled"
      :class="sizeClass"
      :config="calendarConfig"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
export default {
  props: {
    value: {
      type: [String, Date]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: String
    },
    size: {
      type: String,
      default: 'md'
    },
    enableTime: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: 'DD-MM-YYYY HH:II:SS'
    },
    extraClass: {
      type: String,
      default: ''
    }
  },
  components: {
    flatPickr
  },
  data() {
    return {
      mConfig: {
        enableTime: this.enableTime,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true,
        locale: Indonesian,
        altInput: true,
        altFormat: this.format || 'd-m-Y H:i:S',
        static: true
      },
      dateTimeValue: this.value
    }
  },
  computed: {
    dateTime: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        if (!this.dateTimeValue && this.dateTimeValue !== '') {
          return this.$moment().format('YYYY-MM-DD HH:mm:ss')
        }
        return this.dateTimeValue
      }
    },
    calendarConfig() {
      return { ...this.mConfig, ...this.config}
    },
    sizeClass() {
      switch (this.size) {
        case 'sm':
          return 'form-control form-control-sm'
      }
      return 'form-control'
    }
  },
  watch: {
    value(val) {
      this.dateTimeValue = val
    }
  }
}
</script>
