import axiosIns from '@/libs/axios'
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    jadwalKontrol: {
      fields: [
        {
          key: 'tanggal_waktu'
        },
        {
          key: 'unit_tujuan'
        },
        {
          key: 'status'
        }
      ],
      items: []
    }
  },
  mutations: {
    updateField,
    UPDATE_JADWAL_KONTROL(state, jadwalKontrol) {
      state.jadwalKontrol.items = [...jadwalKontrol]
    }
  },
  getters: {
    getField,
    jadwalKontrol(state) {
      return {
        ...state.jadwalKontrol,
        items: state.jadwalKontrol.items.filter((i) => i.poli).map((item) => {		  
          return {
            unit_tujuan: item.poli.deskripsi,
            tanggal_waktu: Vue.moment(item.created_at).format(
              'DD-MMM-YYYY HH:mm:ss'
            ),
            // status: item.poli.status.toString(),
            status: 'Aktif'
          }
        })
      }
    }
  },
  actions: {
    async getJadwalKontrol({ commit }, norm) {
      try {
        const response = await axiosIns.get('jadwal_kontrol/' + norm)
        commit('UPDATE_JADWAL_KONTROL', response.data.data)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
