export function ruanganInstalasi(state) {
  return state.instalasi || []
}

export function penjamin(state) {
  return state.penjamin || []
}

export function penjaminKelas(state) {
  return state.penjaminKelas || []
}

export function penjaminKecelakaan(state) {
  return state.penjaminKecelakaan || []
}

export function shdk(state) {
  return state.statusHubunganKeluarga || []
}

export function jk(state) {
  return state.jenisKelamin || []
}

export function pendidikan(state) {
  return state.pendidikan || []
}

export function pekerjaan(state) {
  return state.pekerjaan || []
}

export function jenisKontak(state) {
  return state.jenisKontak || []
}

export function provinsi(state) {
  return state.provinsi || []
}

export function loket(state) {
  return state.loket || []
}

export function boxPattern(state) {
  return state.boxPattern || []
}

export function groupRuangan(state) {
  return state.groupRuangan || []
}

export function parentGroupRuangan(state) {
  return state.parentGroupRuangan || []
}

export function agama(state) {
  return state.agama || []
}

export function smf(state) {
  return state.smf || []
}

export function profesi(state) {
  return state.profesi || []
}

export function jenisIdentitas(state) {
  return state.jenisIdentitas || []
}

export function jenisKunjungan(state) {
  return state.jenisKunjungan || []
}

export function statusKunjungan(state) {
  return state.statusKunjungan || []
}

export function staffDokter(state) {
  return state.staffDokter || []
}

export function staffMedis(state) {
  return state.staffMedis || []
}

export function jenisRujukan(state) {
  return state.jenisRujukan || []
}

export function poliBpjs(state) {
  return state.poliBpjs || []
}

export function products(state) {
  return state.products || []
}

export function tindakan(state) {
  return state.tindakan || []
}

export function produkGenerik(state) {
  return state.produkGenerik || []
}

export function golonganBarang(state) {
  return state.golonganBarang || []
}

export function aturanMinum(state) {
  return state.aturanMinum || []
}

export function jenisRacik(state) {
  return state.jenisRacik || []
}

export function produkObat(state) {
  return state.produkObat || []
}