export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/pages/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/pages/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/pages/authentication/Register-v2.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/pages/authentication/forgot-password-v1',
    name: 'auth-forgot-password-v1',
    component: () =>
      import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () =>
      import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    }
  },
  {
    path: '/pages/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () =>
      import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/pages/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () =>
      import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/apps/antrean',
    name: 'apps-antrean',
    component: () => import('@/views/apps/antrean/Index.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: 'antrean-application',
      title: 'Antrean',
      key: 'fullPath',
      resource: 'antrean'
    }
  },
  {
    path: '/apps/kunjungan',
    name: 'apps-kunjungan',
    component: () => import('@/views/apps/kunjungan/Index.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      contentClass: 'kunjungan-application',
      title: 'Kunjungan',
      key: 'fullPath',
      resource: 'kunjungan'
    }
  },
  {
    path: '/apps/pasien',
    name: 'apps-pasien',
    component: () => import('@/views/apps/pasien/Pasien.vue'),
    meta: {
      title: 'Daftar Pasien',
      key: 'fullPath',
      resource: 'tabel pasien'
    }
  },
  {
    path: '/apps/pasien/:norm',
    name: 'apps-pasien-detail',
    component: () => import('@/views/apps/pasien/DetailPasien.vue'),
    meta: {
      title(route) {
        return `Pasien ${route.params.norm}`
      },
      key: 'fullPath',
      resource: 'detail pasien',
      keepAlive: false
    }
  },
  {
    path: '/apps/penjualan',
    name: 'apps-penjualan',
    component: () => import('@/views/apps/penjualan/Penjualan.vue'),
    meta: {
      title: 'Penjualan',
      key: 'fullPath',
      resource: 'pos'
    }
  },
  {
    path: '/apps/penerimaan',
    name: 'apps-penerimaan',
    component: () => import('@/views/apps/penerimaan/Penerimaan.vue'),
    meta: {
      title: 'Penerimaan',
      key: 'fullPath',
      resource: 'stock'
    }
  },
  {
    path: '/apps/opname',
    name: 'apps-opname',
    component: () => import('@/views/apps/opname/Index.vue'),
    meta: {
      title: 'Opname',
      key: 'fullPath',
      resource: 'stock'
    }
  },
  {
    path: '/apps/tempo',
    name: 'apps-tempo',
    component: () => import('@/views/apps/hutang/Index.vue'),
    meta: {
      title: 'Pelunasan Tempo',
      key: 'fullPath',
      resource: 'pelunasan hutang'
    }
  },
  {
    path: '/apps/buku-kas',
    name: 'apps-buku-kas',
    component: () => import('@/views/apps/pengeluaran/Index.vue'),
    meta: {
      title: 'Buku Kas',
      key: 'fullPath',
      resource: 'buku kas'
    }
  },
  {
    path: '/apps/laporan',
    name: 'apps-laporan',
    component: () => import('@/views/apps/laporan/tabs/Rangkuman.vue'),
    meta: {
      title: 'Laporan',
      key: 'fullPath',
      resource: 'laporan'
    }
  },
  {
    path: '/apps/laporan/pengunjung',
    name: 'apps-laporan-pengunjung',
    component: () => import('@/views/apps/laporan/tabs/Pengunjung.vue'),
    meta: {
      title: 'Laporan',
      key: 'fullPath',
      resource: 'laporan'
    }
  },
  {
    path: '/apps/laporan/inventori',
    name: 'apps-laporan-inventori',
    component: () => import('@/views/apps/laporan/tabs/Inventory.vue'),
    meta: {
      title: 'Laporan Inventori',
      key: 'fullPath',
      resource: 'laporan'
    }
  },
  {
    path: '/apps/laporan/keuangan',
    name: 'apps-laporan-keuangan',
    component: () => import('@/views/apps/laporan/tabs/Keuangan.vue'),
    meta: {
      title: 'Laporan Keuangan',
      key: 'fullPath',
      resource: 'laporan'
    }
  },
  {
    path: '/apps/laporan/penerimaan',
    name: 'apps-laporan-penerimaan',
    component: () => import('@/views/apps/laporan/tabs/PenerimaanLaporan.vue'),
    meta: {
      title: 'Laporan Penerimaan',
      key: 'fullPath',
      resource: 'laporan'
    }
  },
  {
    path: '/apps/rekap-mcu',
    name: 'apps-rekap-mcu',
    component: () => import('@/views/apps/mcu/Rekap.vue'),
    meta: {
      title: 'Rekap MCU',
      key: 'fullPath',
      resource: 'rm mcu'
    }
  },
  // {
  //     path: '/apps/bisnisku',
  //     name: 'apps-bisnisku',
  //     component: () =>
  //         import ('@/views/apps/bisnis/Bisnisku.vue')
  // },
  {
    path: '/apps/pengaturan',
    name: 'apps-pengaturan',
    component: () => import('@/views/Pengaturan.vue'),
    meta: {
      title: 'Pengaturan',
      key: 'fullPath',
      // TODO: Permission Pengaturan
      // NOTE: tidak ada di navigation
      resource: 'pengaturan'
    }
  },
  // ========== Start Master Data ============
  {
    path: '/apps/icd',
    name: 'apps-icd',
    component: () => import('@/views/apps/master/Icd.vue'),
    meta: {
      title: 'ICD',
      key: 'fullPath'
      // TODO: Permission ICD
    }
  },
  {
    path: '/apps/karyawan',
    name: 'apps-karyawan',
    component: () => import('@/views/apps/master/Karyawan.vue'),
    meta: {
      title: 'Karyawan',
      key: 'fullPath',
      resource: 'karyawan'
    }
  },
  {
    path: '/apps/referensi',
    name: 'apps-referensi',
    component: () => import('@/views/apps/master/Referensi.vue'),
    meta: {
      title: 'Referensi',
      key: 'fullPath',
      resource: 'merk'
    }
  },
  {
    path: '/apps/merk',
    name: 'apps-merk',
    component: () => import('@/views/apps/master/Merk.vue'),
    meta: {
      title: 'Merk',
      key: 'fullPath',
      resource: 'merk'
    }
  },
  {
    path: '/apps/produsen',
    name: 'apps-produsen',
    component: () => import('@/views/apps/master/Produsen.vue'),
    meta: {
      title: 'Produsen',
      key: 'fullPath',
      resource: 'produsen'
    }
  },
  {
    path: '/apps/ruangan',
    name: 'apps-ruangan',
    component: () => import('@/views/apps/master/Ruangan.vue'),
    meta: {
      title: 'Ruangan',
      key: 'fullPath',
      resource: 'ruangan'
    }
  },
  // {
  //     path: '/apps/ruangan-temp',
  //     name: 'apps-ruangan-temp',
  //     component: () =>
  //         import ('@/views/apps/master/RuanganTemp.vue')
  // },
  // {
  //   path: '/apps/ruangan/:id',
  //   name: 'apps-ruangan-detail',
  //   component: () => import('@/components/master/ruangan/Detail.vue'),
  //   meta: {
  //     title(route) {
  //       return `Ruangan ${route.params.id}`
  //     },
  //     key: 'fullPath',
  //     resource: 'ruangan'
  //   }
  // },
  {
    path: '/apps/paket-kunjungan',
    name: 'apps-paket-kunjungan',
    component: () => import('@/views/apps/master/PaketKunjungan.vue'),
    meta: {
      title: 'Paket Kunjungan',
      key: 'fullPath',
      resource: 'paket kunjungan'
    }
  },
  {
    path: '/apps/tindakan',
    name: 'apps-tindakan',
    component: () => import('@/views/apps/master/Tindakan.vue'),
    meta: {
      title: 'Tindakan',
      key: 'fullPath',
      resource: 'tindakan'
    }
  },
  {
    path: '/apps/produk',
    name: 'apps-produk',
    component: () => import('@/views/apps/master/ProdukObat.vue'),
    meta: {
      title: 'Produk',
      key: 'fullPath',
      resource: 'produk'
    }
  },
  {
    path: '/apps/loket',
    name: 'apps-loket',
    component: () => import('@/views/apps/master/Loket.vue'),
    meta: {
      title: 'Loket',
      key: 'fullPath',
      resource: 'loket'
    }
  },
  // ========== End Master Data ============

  {
    path: '/print/etiket',
    name: 'print-etiket',
    component: () => import('@/views/apps/pasien/cetakan/PrintEtiket.vue'),
    meta: {
      title: 'Etiket',
      key: 'fullPath'
      // TODO: Permission Etiket
      // NOTE: tidak ada di navigation
    }
  }
]
