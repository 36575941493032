export default {
  computed: {
    globalOp() {
      return this.$store.getters['app/globalOp']
    }
  },
  watch: {
    globalOp() {
      this.runGlobalEvent()
    }
  },
  methods: {
    runGlobalEvent() {
      // override this method
    },
    hasGlobalEvent(eventName) {
      return Object.keys(this.globalOp).includes(eventName)
    }
  },
  mounted() {
    this.runGlobalEvent()
  }
}