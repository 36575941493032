import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    toast(title, variant='info', icon='AlertCircleIcon', text='') {
      const props = {title}
      if (text) props.text = text
      if (icon) props.icon = icon
      if (variant) props.variant = variant
      this.$toast({
        component: ToastificationContent,
        props
      })
    }
  }
}