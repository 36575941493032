import { flattenDeep } from 'lodash'
import { Ability } from '@casl/ability'
import { initialAbility } from '@/libs/acl/config'
import sLocalStorage from '@/libs/secure-ls'

// NOTE: Selalu muncul tanpa permission
const defaultRoutesPerms = [
  { action: 'view dashboard' }
  // { name: 'view pengaturan' },
]

function parsePermString(str) {
  if (!str) return {}
  const pos = str.indexOf(' ')
  const action = str.slice(0, pos).trim()
  const subject = str.slice(pos).trim()
  if (action === 'view') {
    return [
      {
        action: 'view',
        subject
      },
      {
        action: 'read',
        subject
      }
    ]
  }
  return {
    action,
    subject
  }
}

export function permsByUser() {
  try {
    const perms = JSON.parse(sLocalStorage.get('userData') || '{}')
    if (!perms.ability) return []
    const rawPerms = [...perms.ability, ...defaultRoutesPerms]
    return flattenDeep(rawPerms.map((v) => parsePermString(v.action)))
  } catch (e) {
    // console.error(e)
    sLocalStorage.remove('userData')
    return []
  }
}

export const canNavigate = (to) =>
  to.matched.some((route) => {
    try {
      const userData =
        JSON.parse(sLocalStorage.get('userData') || '{}') || undefined
      if (!userData?.ability && route.name === 'auth-login') {
        sLocalStorage.remove('userData')
        return true
      }
      let ability = new Ability(initialAbility)
      if (userData) {
        const permRole = permsByUser()
        if (permRole) {
          ability = new Ability([
            ...initialAbility,
            ...permRole,
            defaultRoutesPerms.map((v) => parsePermString(v.name))
          ])
        }
      }
      // if (!route.meta.action && !route.meta.resource) return true
      return ability.can(route.meta.action || 'read', route.meta.resource) // || ability.can('manage', 'all')
    } catch (e) {
      // console.error(e.message)
      sLocalStorage.remove('userData')
      return true
    }
  })

export const _ = undefined
