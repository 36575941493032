<template>
  <div
    id="app"
    :class="[skinClasses]"
    class="h-100"
  >
    <component :is="layout">
      <router-view />
    </component>

    <modal-pasien-create
      ref="modalCreate"
      auto-navigate
      fullscreen
    />
    <print-bukti-pendaftaran modal-id="buktiPendaftaranPagePasien" />
    <modal-tambah-kunjungan
      fullscreen
      modal-id="tambahKunjunganGlobal"
      @finish="cetakBuktiPendaftaran"
    />

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import {$themeBreakpoints, $themeColors, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {useCssVar, useWindowSize} from '@vueuse/core'

import store from '@/store'

import sLocalStorage from '@core/utils/secure-storage'

import HasOperation from '@core/mixins/has-operation'
import ModalPasienCreate from '@/components/pasien/ModalCreate.vue'
import PrintBuktiPendaftaran from '@/views/apps/pasien/cetakan/PrintBuktiPendaftaran.vue'
import ModalTambahKunjungan from '@/views/apps/components/ModalTambahKunjungan.vue'
import { mapGetters } from 'vuex'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

sLocalStorage.setSecuredFields([
  {
    name: 'userData',
    type: 'object'
  }
])

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
// const LayoutHorizontal = () =>
// import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    // LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,

    ModalPasienCreate,
    ModalTambahKunjungan,
    PrintBuktiPendaftaran
  },
  mixins: [HasOperation],
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    ...mapGetters('pasien', ['allData'])
  },
  async mounted() {
    // workaround
    await this.$store.dispatch('pasien/getPasienByNorm', 1)
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark'
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }

    // Set RTL
    const {isRTL} = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    ;(async () => {
      const t = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
      if (!t) return
      try {
        const r = await this.$axios.get('/api/v1/user-data')
        const { userData } = r.data
        sLocalStorage.setItem('userData', JSON.stringify(userData))
        // this.$ability.update([...userData.ability, { action: 'view dashboard' }])
        this.$store.dispatch('user/setData', userData)
        await this.$store.dispatch('pasien/getReferences')  
        this.$store.dispatch('masterdata/getSHDK')
        this.$store.dispatch('masterdata/getJK')
        this.$store.dispatch('masterdata/getPendidikan')
        this.$store.dispatch('masterdata/getPekerjaan')
        this.$store.dispatch('masterdata/getJenisKontak')
        this.$store.dispatch('masterdata/getProvinsi')
        this.$store.dispatch('masterdata/getProfesi')
        this.$store.dispatch('masterdata/getAgama')
        this.$store.dispatch('masterdata/getSmf')
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch('auth/logout')
        }
      }
    })()
    // this.$store.dispatch('masterdata/getProdukObat')
    // setInterval(() => {
    //   this.$store.dispatch('masterdata/getProdukObat')
    // }, 3000 * 60) // 1 menit
  },
  methods: {
    runGlobalEvent() {
      if (this.hasGlobalEvent('createPasien')) {
        this.openModalCreate()
        this.$store.dispatch('app/removeOperation', ['createPasien'])
      }
      if (this.hasGlobalEvent('createKunjungan')) {
        this.$bvModal.show('tambahKunjunganGlobal')
        this.$store.dispatch('app/removeOperation', ['createKunjungan'])
      }
    },
    openModalCreate() {
      // this.$refs.modalCreate.$refs.wizard.reset()
      // this.$refs.modalCreate.$refs.step1.reset()
      this.$store.commit('pasien/UPDATE_FORM_EDIT', {})
      this.$bvModal.show('modal-create')
    },
    cetakBuktiPendaftaran() {
      this.$bvModal
        .msgBoxConfirm('Apakah anda ingin mencetak bukti pendaftaran', {
          title: 'Konfirmasi Cetak Bukti Pendaftaran',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Cetak',
          cancelTitle: 'Tidak',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(async (v) => {
          if (!v) return
          await this.$htmlToPaper('buktiPendaftaranPagePasien', {
            styles: ['/print/style.css', '/print/buktiPendaftaran.css']
          })
        })
        .catch((_err) => {
          // An error occurred
        })
    }
  },
  setup() {
    const {skin, skinClasses} = useAppConfig()
    const {enableScrollToTop} = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: false,
      closeOnClick: true,
      closeButton: false,
      icon: true,
      timeout: 1000,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const {width: windowWidth} = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop
    }
  }
}
</script>
