import sLocalStorage from '@core/utils/secure-storage'

export default {
  namespaced: true,
  state: {
    data: null
  },
  getters: {
    data(state) {
      let userData = state.data

      if (!userData) {
        userData = sLocalStorage.getItem('userData')
          ? JSON.parse(sLocalStorage.getItem('userData'))
          : null
      }

      if (!userData) return null
      return {
        ...userData,
        entity: !userData?.entity
          ? {
              name: 'RSU DADI KELUARGA CIAMIS',
              address: 'Jl. R. E. Martadinata No.333-335, Petirhilir, Baregbeg',
              code: '-',
              phone: '-',
              logo: require('@/assets/gambar/deka.png')
            }
          : userData.entity.entity
      }
    },
    can(state) {
      return (permission) => {
        let userData = state.data
        if (!userData) {
          userData = sLocalStorage.getItem('userData')
            ? JSON.parse(sLocalStorage.getItem('userData'))
            : null
        }
        const permissions = userData?.ability
        if (!permissions) return false
        return permissions.some((ability) => {
          return (ability.action === permission)
        })
      }
    }
  },
  mutations: {
    UPDATE_DATA(state, payload) {
      return (state.data = payload)
    }
  },
  actions: {
    setData({ commit }, payload) {
      commit('UPDATE_DATA', payload)
    }
  }
}
