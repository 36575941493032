import references from '@/@core/app-config/references'
import axiosIns from '@/libs/axios'
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { objectClean, urlToObject } from '@/libs/helpers'
import { serialize } from 'object-to-formdata'

export default {
  namespaced: true,
  state: {
    columns: [
      {
        label: 'No. RM',
        field: 'norm',
        filterOptions: {
          enabled: true,
          placeholder: 'Search NORM'
        }
      },
      {
        label: 'Pasien',
        field: 'nama',
        filterOptions: {
          enabled: true,
          placeholder: 'Search Nama'
        }
      },
      {
        label: 'Alamat',
        field: 'alamat',
        filterOptions: {
          enabled: true,
          placeholder: 'Search Alamat'
        }
      },
      {
        label: 'Tanggal Lahir',
        field: 'tanggal_lahir',
        filterOptions: {
          enabled: true,
          placeholder: 'Search Tanggal Lahir'
        }
      },
      {
        label: 'Umur',
        field: 'umur',
        filterOptions: {
          enabled: true,
          placeholder: 'Search Umur'
        }
      },
      {
        label: 'Status',
        field: 'status',
        filterOptions: {
          enabled: true,
          placeholder: 'Search Status'
        }
      },
      {
        label: 'Aksi',
        field: 'action',
        width: '120px',
        tdClass: 'text-center'
      }
    ],
    allRows: [],
    referenceKeys: [
      'Kelas',
      'Jenis Penjamin Kecelakaan',
      'Jenis Kartu Asuransi / Penjamin',
      'Jenis Kelamin',
      'Jenis Kartu Identitas',
      'Jenis Kontak',
      'Jenis Profesi',
      'Jenis Kunjungan',
      'Jenis Rujukan',
      'Jenis Staf Medis Fungsional',
      'Agama',
      'Status Perkawinan',
      'Pendidikan',
      'Pekerjaan',
      'Daftar Suku',
      'Golongan Darah',
      'Golongan Barang',
      'Generik/Zat Aktif',
      'Ruangan Rujukan',
      'Status Aktifitas Kunjungan',
      'Status Hubungan Dalam Keluarga'
    ],
    references: {
      kewarganegaraan: [
        {
          value: 1,
          title: 'WNI'
        },
        {
          value: 2,
          title: 'WNA'
        }
      ]
    },
    isLoading: true,
    total: 0,
    serverParams: {
      columnFilters: {},
      sort: [
        {
          field: '',
          type: ''
        }
      ],
      page: 1,
      perPage: 10,
      query: ''
    },
    form: {},
    columnFilters: {},
    foto: '',
    filteredPasien: [],
    allData: null
  },
  getters: {
    getField,
    allRows(state) {
      return state.allRows.map((row) => ({
        ...row,
        tanggal_lahir: Vue.moment(row.tanggal_lahir).format('DD/MMM/YYYY'),
        status: row.status ? row.status.title : '',
        status_value: row.status ? row.status.value : ''
      }))
    },
    references(state) {
      return state.references
    },
    cleanForm(state) {
      const cleanForm = objectClean(state.form)
      return cleanForm
    },
    alamatIdentitas(state) {
      return {
        alamat_identitas: state.form.alamat_identitas,
        provinsi_identitas: state.form.provinsi_identitas?.value,
        wilayah_identitas: state.form.wilayah_identitas?.value,
        kecamatan_identitas: state.form.kecamatan_identitas?.value,
        kelurahan_identitas: state.form.kelurahan_identitas?.value,
        rt_identitas: state.form.rt_identitas,
        rw_identitas: state.form.rw_identitas,
        kodepos_identitas: state.form.kodepos_identitas
      }
    },
    filteredPasien(state) {
      return state.filteredPasien.map((pasien) => ({
        title: `${pasien.norm} - ${pasien.nama}`,
        value: pasien.norm
      }))
    },
    allData(state) {
      return state.allData
    }
  },
  mutations: {
    updateField,
    UPDATE_ALL_ROWS(state, allRows) {
      Vue.set(state, 'allRows', [...allRows])
    },
    ADD_REFERENCE(state, { key, value }) {
      state.references[key] = value
    },
    UPDATE_TOTAL(state, total) {
      state.total = total
    },
    async UPDATE_FORM_EDIT(state, data) {
      state.form = { ...data }
      state.allData = data

      if (Object.keys(data).length) {
        state.form.norm = data.norm
        state.form.jenis_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien.find((v) => v.jenis.value == 1)?.jenis
            : null
        state.form.nomor_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien.find((v) => v.jenis.value == 1)?.nomor
            : null
        state.form.nomor_identitas_npwp =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien.find((v) => v.jenis.value == 8)?.nomor
            : null

        state.form.tanggal_lahir_formatted = Vue.moment(
          new Date(data.tanggal_lahir)
        ).format('DD/MM/YYYY')

        state.form.tanggal_lahir = Vue.moment(
          new Date(data.tanggal_lahir)
        ).format('YYYY-MM-DD')

        state.form.nomor_kontak_telp =
          data.kontak_pasien.length > 0
            ? data.kontak_pasien.find((kontak) => kontak.jenis.value == 3)
                ?.nomor || null
            : null
        state.form.nomor_kontak_wa =
          data.kontak_pasien.length > 0
            ? data.kontak_pasien.find((kontak) => kontak.jenis.value == 4)
                ?.nomor || null
            : null
        state.form.shdk_keluarga = data.keluarga_pasien?.shdk
        state.form.nama_keluarga = data.keluarga_pasien?.nama
        state.form.jenis_kelamin_keluarga = data.keluarga_pasien?.jenis_kelamin
        state.form.pendidikan_keluarga = data.keluarga_pasien?.pendidikan
        state.form.pekerjaan_keluarga = data.keluarga_pasien?.pekerjaan
        state.form.status_perkawinan_keluarga =
          data.keluarga_pasien?.status_perkawinan
        state.form.agama_keluarga = data.keluarga_pasien?.agama
        state.form.suku_keluarga = data.keluarga_pasien?.suku
        state.form.golongan_darah_keluarga =
          data.keluarga_pasien?.golongan_darah
        state.form.alamat_keluarga = data.keluarga_pasien?.alamat
        state.form.email_keluarga = data.keluarga_pasien?.email
        state.form.nomor_kontak_keluarga_telp =
          data.kontak_keluarga_pasien.length > 0
            ? data.kontak_keluarga_pasien[0]?.nomor
            : null
        state.form.nomor_kontak_keluarga_wa =
          data.kontak_keluarga_pasien.length > 0
            ? data.kontak_keluarga_pasien[0]?.nomor
            : null
        state.form.status_pasien = data.referensi_status_pasien
          ? data.referensi_status_pasien.title
          : ''
        state.form.id_keluarga = data.keluarga_pasien?.id?.value
        state.form.no_identitas_keluarga =
          data.keluarga_pasien?.no_identitas_keluarga
        state.form.tempat_lahir_keluarga = data.keluarga_pasien?.tempat_lahir
        state.form.tanggal_lahir_keluarga = data.keluarga_pasien?.tanggal_lahir
        state.form.tanggal_lahir_keluarga_formatted = data.keluarga_pasien
          ?.tanggal_lahir
          ? Vue.moment(new Date(data.keluarga_pasien.tanggal_lahir)).format(
              'DD/MM/YYYY'
            )
          : null

        state.form.kewarganegaraan_keluarga =
          data.keluarga_pasien?.kewarganegaraan
        state.form.alamat_keluarga = data.keluarga_pasien?.alamat
        state.form.alamat_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.alamat || null
            : null
        state.form.provinsi_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.provinsi || null
            : null
        state.form.wilayah_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.wilayah || null
            : null
        state.form.kecamatan_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.kecamatan || null
            : null
        state.form.kelurahan_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.kelurahan || null
            : null
        state.form.rt_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.rt || null
            : null
        state.form.rw_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.rw || null
            : null
        state.form.kodepos_identitas =
          data.identitas_pasien.length > 0
            ? data.identitas_pasien[0]?.kodepos || null
            : null
        state.form.created_at = Vue.moment(new Date(data.created_at)).format(
          'YYYY-MM-DD HH:MM:SS'
        )
        state.form.created_at_formatted = Vue.moment(
          new Date(data.created_at)
        ).format('DD MMMM YYYY')

        // Dokumen
        const ktp = data.dokumen_pasien.find((dokumen) => dokumen.type == 'ktp')
        const akta_kelahiran = data.dokumen_pasien.find(
          (dokumen) => dokumen.type == 'akta_kelahiran'
        )
        const sim = data.dokumen_pasien.find((dokumen) => dokumen.type == 'sim')
        const npwp = data.dokumen_pasien.find(
          (dokumen) => dokumen.type == 'npwp'
        )
        const bpjs = data.dokumen_pasien.find(
          (dokumen) => dokumen.type == 'bpjs'
        )
        const lainnya = data.dokumen_pasien.find(
          (dokumen) => dokumen.type == 'lainnya'
        )

        state.form.file_ktp_url = ktp?.file_url
        state.form.file_akta_url = akta_kelahiran?.file_url
        state.form.file_sim_url = sim?.file_url
        state.form.file_npwp_url = npwp?.file_url
        state.form.file_bpjs_url = bpjs?.file_url

        state.form.file_ktp = await urlToObject(ktp?.file_url, ktp?.filename)
        state.form.file_akta = await urlToObject(
          akta_kelahiran?.file_url,
          akta_kelahiran?.filename
        )

        state.form.file_sim = await urlToObject(sim?.file_url, sim?.filename)
        state.form.file_npwp = await urlToObject(npwp?.file_url, npwp?.filename)
        state.form.file_bpjs = await urlToObject(bpjs?.file_url, bpjs?.filename)
        state.form.file_lainnya = await urlToObject(
          lainnya?.file_url,
          lainnya?.filename
        )

        state.form.perusahaan = data.perusahaan
        state.form.bagian = data.bagian
      }
    },
    CLEAR_FORM(state) {
      state.form = {}
    },
    UPDATE_DOKUMEN(state, dokumen) {
      state.dokumen = [...dokumen]
    },
    UPDATE_FILTERED_PASIEN(state, pasien) {
      state.filteredPasien = [...pasien]
    }
  },
  actions: {
    async nik({ commit, state }, nik) {
      try {
        const res = await axiosIns.post(`pasien/validate-nik/${nik}`)

        return res
      } catch (err) {
        console.log(err)
        return false
      }
    },
    async init({ dispatch }) {
      dispatch('getAllRows')
    },
    async getAllRows({ commit, state }) {
      try {
        const response = await axiosIns.get('pasien', {
          params: {
            limit: state.serverParams.perPage,
            page: state.serverParams.page,
            ...state.columnFilters
          }
        })
        commit('UPDATE_ALL_ROWS', response.data.data)
        commit('UPDATE_TOTAL', response.data.meta.total)
      } catch (e) {
        console.log(e)
      }
    },
    getReferences({ commit, state }) {
      const ls = []
      state.referenceKeys.forEach((key) => {
        if (state.references[key]) {
          return state.references[key]
        }

        ls[key] = localStorage.getItem(key)

        if (ls[key]) {
          commit('ADD_REFERENCE', { key, value: JSON.parse(ls[key]) })

          return ls[key]
        }

        axiosIns.get(`referensi/${references[key]}?active=true`).then((response) => {
          commit('ADD_REFERENCE', {
            key,
            value: response.data.data
          })
          // localStorage.setItem(key, JSON.stringify(response.data.data))
        })
      })
    },
    async savePasien({ getters, commit, dispatch }) {
      try {
        const { cleanForm } = getters
        cleanForm.tanggal_lahir = Vue.moment(
          cleanForm.tanggal_lahir_formatted,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD')
        cleanForm.tanggal_lahir_keluarga =
          cleanForm.tanggal_lahir_keluarga_formatted
            ? Vue.moment(
                cleanForm.tanggal_lahir_keluarga_formatted,
                'DD/MM/YYYY'
              ).format('YYYY-MM-DD') || null
            : null

        if (cleanForm.tanggal_lahir == 'Invalid date') {
          cleanForm.tanggal_lahir = null
        }
        if (cleanForm.tanggal_lahir_keluarga == 'Invalid date') {
          cleanForm.tanggal_lahir_keluarga = null
        }
        const formData = serialize(cleanForm)
        const { data } = await axiosIns.post('/pasien/store', formData)
        if (data.message && !data.error) {
          commit('CLEAR_FORM')
          return [true, 'Sukses Menambahkan Pasien Baru', data.message]
        }

        return [false, data.message]
      } catch (e) {
        if (e.response) {
          const { errors = false } = e.response.data
          if (errors) {
            const firstError = Object.keys(errors)[0]
            const firstErrorMessage = errors[firstError][0]
            return [false, firstErrorMessage]
          }
          return [false, e.response.data.message]
        }
        console.error(e)
        return [false, e.message]
      } finally {
        dispatch('getAllRows')
      }
    },
    async updatePasien({ getters, commit, dispatch, state }) {
      try {
        const { cleanForm } = getters

        if (cleanForm.tanggal_lahir_formatted == 'Invalid date') {
          cleanForm.tanggal_lahir_formatted = null
        }
        // cleanForm.tanggal_lahir = cleanForm.tanggal_lahir_formatted
        cleanForm.tanggal_lahir = Vue.moment(
          cleanForm.tanggal_lahir_formatted,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD')
        if (cleanForm.tanggal_lahir_keluarga_formatted == 'Invalid date') {
          cleanForm.tanggal_lahir_keluarga_formatted = null
        }
        cleanForm.tanggal_lahir_keluarga =
          cleanForm.tanggal_lahir_keluarga_formatted
        const formData = serialize(cleanForm)
        const { data } = await axiosIns.post(
          `/pasien/update/${state.form.norm}?_method=PUT`,
          formData
        )
        if (data.message && !data.error) {
          return [true, 'Sukses Menambahkan Pasien Baru', data.message]
        }

        return [false, data.message]
      } catch (e) {
        console.error(e)
        if (e.response) {
          const { errors = false } = e.response.data
          if (errors) {
            const firstError = Object.keys(errors)[0]
            const firstErrorMessage = errors[firstError][0]
            return [false, firstErrorMessage]
          }
          return [false, e.response.data.message]
        }
        return [false, e.message]
      } finally {
        dispatch('getAllRows')
      }
    },
    async getPasienByNorm({ commit }, norm) {
      try {
        commit('UPDATE_FORM_EDIT', {})
        const response = await axiosIns.get(`/pasien/${norm}`)
        commit('UPDATE_FORM_EDIT', response.data.data)
      } catch (e) {
        console.error(e)
      }
    },
    async uploadFoto({ state, dispatch }) {
      try {
        const formData = new FormData()
        formData.append('foto', state.foto)
        await axiosIns.post(`pasien/upload/foto/${state.form.norm}`, formData)
      } catch (e) {
        console.log(e)
      } finally {
        await dispatch('getPasienByNorm', state.form.norm)
      }
    },
    async filterPasien({ commit }, keyword) {
      let query

      if (keyword.match(/[0-9]{16}/))
        query = {
          nik: keyword
        }
      else if (keyword.match(/[0-9]{1,16}/))
        query = {
          norm: keyword
        }
      else
        query = {
          nama: keyword
        }
      const response = await axiosIns.get('pasien', {
        params: query
      })
      commit('UPDATE_FILTERED_PASIEN', response.data.data)
    },
    async uploadWebCam({ state, dispatch }, image) {
      try {
        await axiosIns.post(`pasien/upload/webcam/${state.form.norm}`, {
          base64: image
        })
      } catch (e) {
        console.log(e)
      } finally {
        await dispatch('getPasienByNorm', state.form.norm)
      }
    }
  }
}
